import {
    SET_LOADING,
    GET_BRANCH,
    CREATE_BRANCH,
    DELETE_BRANCH,
    DETAIL_BRANCH,
    UPDATE_BRANCH,
    GET_BOOTH,
    CREATE_BOOTH,
    DELETE_BOOTH,
    DETAIL_BOOTH,
    UPDATE_BOOTH
} from '../actions/BranchActions'

const initialState = {
    list: {
        loading: false,
        data: {},
    },
    detail: {
        loading: false,
        data: null
    },
    create: {
        loading: false,
        data: null
    },
    update: {
        loading: false,
        data: null
    },
    delete: {
        loading: false,
        data: null,
    },
    listBooth: {
        loading: false,
        data: {},
    },
    detailBooth: {
        loading: false,
        data: null
    },
    createBooth: {
        loading: false,
        data: null
    },
    updateBooth: {
        loading: false,
        data: null
    },
    deleteBooth: {
        loading: false,
        data: null,
    }
}

const BranchReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING: {
            const obj = {
                ...state,
            }
            obj[action.payload.stateName].loading = action.payload.value;
            return obj
        }
        case GET_BRANCH: {
            return {
                ...state,
                list: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case DETAIL_BRANCH: {
            return {
                ...state,
                detail: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case CREATE_BRANCH: {
            return {
                ...state,
                create: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case UPDATE_BRANCH: {
            return {
                ...state,
                update: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case DELETE_BRANCH: {
            return {
                ...state,
                delete: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case GET_BOOTH: {
            return {
                ...state,
                listBooth: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case DETAIL_BOOTH: {
            return {
                ...state,
                detailBooth: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case CREATE_BOOTH: {
            return {
                ...state,
                createBooth: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case UPDATE_BOOTH: {
            return {
                ...state,
                updateBooth: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case DELETE_BOOTH: {
            return {
                ...state,
                deleteBooth: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        default: {
            return { ...state }
        }
    }
}

export default BranchReducer
