import axios from 'axios.js'

export const LOAD_PROVINCE = 'WILAYAH_LOAD_PROVINCE'
export const LOAD_REGENCY = 'WILAYAH_LOAD_REGENCY'
export const LOAD_DISTRICT = 'WILAYAH_LOAD_DISTRICT'
export const SET_LOADING = 'WILAYAH_SET_LOADING'

export const getProvince = () => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'listProvince',
            value: true,
        },
    })
    axios.get('/wilayah/province').then((res) => {
        dispatch({
            type: LOAD_PROVINCE,
            payload: res.data.data,
        })
    })
}

export const getRegency = (params) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'listRegency',
            value: true,
        },
    })
    axios.get('/wilayah/regency', {
        params
    }).then((res) => {
        dispatch({
            type: LOAD_REGENCY,
            payload: res.data.data,
        })
    })
}

export const getDistrict = (regency_id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'listDistrict',
            value: true,
        },
    })
    axios.get('/wilayah/district', {
        params: {
            regency_id
        }
    }).then((res) => {
        dispatch({
            type: LOAD_DISTRICT,
            payload: res.data.data,
        })
    })
}