import axios from 'axios.js'
import { showNotification } from './GlobalActions'

export const SET_LOADING = 'user/SET_LOADING'
export const GET_USER = 'user/GET_USER'
export const CREATE_USER = 'user/CREATE_USER'
export const DELETE_USER = 'user/DELETE_USER'
export const DETAIL_USER = 'user/DETAIL_USER'
export const UPDATE_USER = 'user/UPDATE_USER'


export const getUser = (params = {}) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'list',
            value: true,
        },
    })
    return axios.get('/user', {
        params: {
            type: 'user,sales',
            ...params,
        }
    }).then((res) => {
        dispatch({
            type: GET_USER,
            payload: res.data,
        })
    })
}

export const createUser = (attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'create',
            value: true,
        },
    })
    return axios.post('/user', attributes).then((res) => {
        dispatch({
            type: CREATE_USER,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'User Berhasil ditambahkan',
            severity: 'success',
        }))
    })
}

export const deleteUser = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'delete',
            value: true,
        },
    })
    return axios.delete(`/user/${id}`).then((res) => {
        dispatch({
            type: DELETE_USER,
            payload: res.data,
        })

        dispatch(showNotification({
            open: true,
            message: 'User berhasil dihapus',
            severity: 'success',
        }))
    })
}

export const detailUser = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'detail',
            value: true,
        },
    })
    return axios.get(`/user/${id}`).then((res) => {
        dispatch({
            type: DETAIL_USER,
            payload: res.data,
        })
    })
}

export const updateUser = (id, attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'update',
            value: true,
        },
    })
    return axios.patch(`/user/${id}`, attributes).then((res) => {
        dispatch({
            type: UPDATE_USER,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'User Berhasil Diperbarui',
            severity: 'success',
        }))
    })
}