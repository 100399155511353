import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const UserIndex = Loadable(lazy(() => import("./UserIndex")));
const UserForm = Loadable(lazy(() => import("./UserForm")));

const userRoutes = [
    {
        path: '/user/default',
        element: <UserIndex />,
    },
    {
        path: '/user/add',
        element: <UserForm formType="create" />
    },
    {
        path: '/user/edit/:id',
        element: <UserForm formType="edit" />
    },
    {
        path: '/user/profile',
        element: <UserForm isProfile formType="edit" />
    },
]

export default userRoutes
