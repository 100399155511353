import { SHOW_NOTIFICATION, SHOW_CONFIRMATION } from "../actions/GlobalActions";

const initialState = {
  notification: {
    open: false,
    message: '',
    severity: 'success',
  },
  confirmDialog: {
    open: false,
    message: 'Are you sure you want to do this ?',
    onConfirm: () => {},
    onCancel: () => {},
  }
}

const GlobalReducer = function (state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          ...action.payload
        }
      }
      case SHOW_CONFIRMATION:
        return {
          ...state,
          confirmDialog: {
            ...state.confirmDialog,
            ...action.payload
          }
        }
    default:
      return {...state}
  }
}

export default GlobalReducer