import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'
import BranchReducer from './BranchReducer'
import WilayahReducer from './WilayahReducer'
import GlobalReducer from './GlobalReducer'
import UserReducer from './UserReducer'
import TransactionReducer from './TransactionReducer'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    branch: BranchReducer,
    wilayah: WilayahReducer,
    global: GlobalReducer,
    user: UserReducer,
    transaction: TransactionReducer,
})

export default RootReducer
