import React from 'react'
import { Card } from '@mui/material'
import { styled, Box } from '@mui/system'

const CardRoot = styled(Card)(() => ({
    height: '100%',
    padding: '20px 24px',
}))

const CardTitle = styled('div')(({ subtitle }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: !subtitle && "16px",
}))

const SimpleCard = ({ children, title, subtitle, icon, style, rightSection }) => {
    return (
        <CardRoot elevation={6} style={style}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <CardTitle subtitle={subtitle}>
                        {title}
                    </CardTitle>
                    {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
                </div>
                <div>
                    {rightSection}
                </div>
            </div>
            {children}

        </CardRoot>
    )
}

export default SimpleCard
