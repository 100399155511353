import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const BranchIndex = Loadable(lazy(() => import("./BranchIndex")));
const BranchForm = Loadable(lazy(() => import("./BranchForm")));

const branchRoutes = [
    {
        path: '/branch/default',
        element: <BranchIndex />,
    },
    {
        path: '/branch/add',
        element: <BranchForm formType="create" />
    },
    {
        path: '/branch/edit/:id',
        element: <BranchForm formType="edit" />
    }
]

export default branchRoutes
