import axios from 'axios.js'
import { showNotification } from './GlobalActions'

export const SET_LOADING = 'BRANCH_SET_LOADING'
export const GET_BRANCH = 'BRANCH_GET_BRANCH'
export const CREATE_BRANCH = 'BRANCH_CREATE_BRANCH'
export const DELETE_BRANCH = 'BRANCH_DELETE_BRANCH'
export const DETAIL_BRANCH = 'BRANCH_DETAIL_BRANCH'
export const UPDATE_BRANCH = 'BRANCH_UPDATE_BRANCH'
export const GET_BOOTH = 'BRANCH_GET_BOOTH'
export const CREATE_BOOTH = 'BRANCH_CREATE_BOOTH'
export const DELETE_BOOTH = 'BRANCH_DELETE_BOOTH'
export const DETAIL_BOOTH = 'BRANCH_DETAIL_BOOTH'
export const UPDATE_BOOTH = 'BRANCH_UPDATE_BOOTH'


export const getBranch = (params) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'list',
            value: true,
        },
    })
    return axios.get('/branch', {
        params,
    }).then((res) => {
        dispatch({
            type: GET_BRANCH,
            payload: res.data,
        })
    })
}

export const createBranch = (attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'create',
            value: true,
        },
    })
    return axios.post('/branch', attributes).then((res) => {
        dispatch({
            type: CREATE_BRANCH,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'Branch Berhasil ditambahkan',
            severity: 'success',
        }))
    })
}

export const deleteBranch = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'delete',
            value: true,
        },
    })
    return axios.delete(`/branch/${id}`).then((res) => {
        dispatch({
            type: DELETE_BRANCH,
            payload: res.data,
        })

        dispatch(showNotification({
            open: true,
            message: 'Branch berhasil dihapus',
            severity: 'success',
        }))
    })
}

export const detailBranch = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'detail',
            value: true,
        },
    })
    return axios.get(`/branch/${id}`).then((res) => {
        dispatch({
            type: DETAIL_BRANCH,
            payload: res.data,
        })
    })
}

export const updateBranch = (id, attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'update',
            value: true,
        },
    })
    return axios.patch(`/branch/${id}`, attributes).then((res) => {
        dispatch({
            type: UPDATE_BRANCH,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'Branch Berhasil Diperbarui',
            severity: 'success',
        }))
    })
}

export const getBooth = (branch_id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'listBooth',
            value: true,
        },
    })
    return axios.get('/booth', {
        params: {
            branch_id
        }
    }).then((res) => {
        dispatch({
            type: GET_BOOTH,
            payload: res.data,
        })
    })
}

export const createBooth = (attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'createBooth',
            value: true,
        },
    })
    return axios.post('/booth', attributes).then((res) => {
        dispatch({
            type: CREATE_BOOTH,
            payload: res.data,
        })

        
        dispatch(showNotification({
            open: true,
            message: 'Booth Berhasil ditambahkan',
            severity: 'success',
        }))
    })
}

export const deleteBooth = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'deleteBooth',
            value: true,
        },
    })
    return axios.delete(`/booth/${id}`).then((res) => {
        dispatch({
            type: DELETE_BOOTH,
            payload: res.data,
        })

        
        dispatch(showNotification({
            open: true,
            message: 'Booth Berhasil dihapus',
            severity: 'success',
        }))
    })
}

export const detailBooth = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'detailBooth',
            value: true,
        },
    })
    return axios.get(`/booth/${id}`).then((res) => {
        dispatch({
            type: DETAIL_BOOTH,
            payload: res.data,
        })
    })
}

export const updateBooth = (id, attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'updateBooth',
            value: true,
        },
    })
    return axios.patch(`/booth/${id}`, attributes).then((res) => {
        dispatch({
            type: UPDATE_BOOTH,
            payload: res.data,
        })
        
        dispatch(showNotification({
            open: true,
            message: 'Booth Berhasil diperbarui',
            severity: 'success',
        }))
    })
}