import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const TransactionIndex = Loadable(lazy(() => import("./TransactionIndex")));
const TransactionForm = Loadable(lazy(() => import("./TransactionForm")));

const transactionRoutes = [
    {
        path: '/transaction/default',
        element: <TransactionIndex />,
    },
    {
        path: '/transaction/add',
        element: <TransactionForm formType="create" />
    },
    {
        path: '/transaction/show/:id',
        element: <TransactionForm />
    }
]

export default transactionRoutes
