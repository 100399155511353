import axios from 'axios'

// export const baseURL = 'http://localhost:3333';
export const baseURL = 'https://api-photomatics.demo-kota.com';
// export const baseURL = 'https://api.photomatics.site';

const axiosInstance = axios.create({
    baseURL,
})

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if(error.response) {
            if(error.response.status === 400 && error.response.data.message === 'Invalid credentials' && !window.location.href.indexOf('/session')) {
                window.localStorage.clear();
                window.location.reload();
            }
        }

        return Promise.reject(
            (error.response && error.response.data) || 'Something went wrong!'
        )
    }
        
)

export default axiosInstance