export const SHOW_NOTIFICATION = 'global/SHOW_NOTIFICATION'
export const SHOW_CONFIRMATION = 'global/SHOW_CONFIRMATION'

export const showNotification = (payload) => (dispatch) => {
  dispatch({
    type: SHOW_NOTIFICATION,
    payload,
  })
}

export const showConfirmation = (payload) => (dispatch) => {
  dispatch({
    type: SHOW_CONFIRMATION,
    payload,
  })
}


