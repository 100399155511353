import {
    SET_LOADING,
    GET_TRANSACTION,
    CREATE_TRANSACTION,
    DELETE_TRANSACTION,
    DETAIL_TRANSACTION,
    UPDATE_TRANSACTION,
} from '../actions/TransactionActions'

const initialState = {
    list: {
        loading: false,
        data: {},
    },
    detail: {
        loading: false,
        data: null
    },
    create: {
        loading: false,
        data: null
    },
    update: {
        loading: false,
        data: null
    },
    delete: {
        loading: false,
        data: null,
    },
}

const TransactionReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING: {
            const obj = {
                ...state,
            }
            obj[action.payload.stateName].loading = action.payload.value;
            return obj
        }
        case GET_TRANSACTION: {
            return {
                ...state,
                list: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case DETAIL_TRANSACTION: {
            return {
                ...state,
                detail: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case CREATE_TRANSACTION: {
            return {
                ...state,
                create: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case UPDATE_TRANSACTION: {
            return {
                ...state,
                update: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case DELETE_TRANSACTION: {
            return {
                ...state,
                delete: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        default: {
            return { ...state }
        }
    }
}

export default TransactionReducer
