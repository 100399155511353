import axios from 'axios.js'
import { showNotification } from './GlobalActions'

export const SET_LOADING = 'user/SET_LOADING'
export const GET_TRANSACTION = 'user/GET_TRANSACTION'
export const CREATE_TRANSACTION = 'user/CREATE_TRANSACTION'
export const DELETE_TRANSACTION = 'user/DELETE_TRANSACTION'
export const DETAIL_TRANSACTION = 'user/DETAIL_TRANSACTION'
export const UPDATE_TRANSACTION = 'user/UPDATE_TRANSACTION'


export const getTransaction = (params = {}) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'list',
            value: true,
        },
    })
    return axios.get('/transaction', {
        params
    }).then((res) => {
        dispatch({
            type: GET_TRANSACTION,
            payload: res.data,
        })
    })
}

export const createTransaction = (attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'create',
            value: true,
        },
    })
    return axios.post('/transaction', attributes).then((res) => {
        dispatch({
            type: CREATE_TRANSACTION,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'Transaction Berhasil ditambahkan',
            severity: 'success',
        }))
    })
}

export const deleteTransaction = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'delete',
            value: true,
        },
    })
    return axios.delete(`/transaction/${id}`).then((res) => {
        dispatch({
            type: DELETE_TRANSACTION,
            payload: res.data,
        })

        dispatch(showNotification({
            open: true,
            message: 'Transaction berhasil dihapus',
            severity: 'success',
        }))
    })
}

export const detailTransaction = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'detail',
            value: true,
        },
    })
    return axios.get(`/transaction/${id}`).then((res) => {
        dispatch({
            type: DETAIL_TRANSACTION,
            payload: res.data,
        })
    })
}

export const updateTransaction = (id, attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'update',
            value: true,
        },
    })
    return axios.patch(`/transaction/${id}`, attributes).then((res) => {
        dispatch({
            type: UPDATE_TRANSACTION,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'Transaction Berhasil Diperbarui',
            severity: 'success',
        }))
    })
}