import {
    SET_LOADING,
    LOAD_PROVINCE,
    LOAD_REGENCY,
    LOAD_DISTRICT
} from '../actions/WilayahActions'

const initialState = {
    listProvince: {
        loading: false,
        data: [],
    },
    listRegency: {
        loading: false,
        data: [],
    },
    listDistrict: {
        loading: false,
        data: [],
    },
}

const BranchReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING: {
            const obj = {
                ...state,
            }
            obj[action.payload.stateName].loading = action.payload.value
            return obj
        }
        case LOAD_PROVINCE: {
            return {
                ...state,
                listProvince: {
                    data: action.payload,
                    loading: false,
                },
            }
        }
        case LOAD_REGENCY: {
            return {
                ...state,
                listRegency: {
                    data: action.payload,
                    loading: false,
                },
            }
        }
        case LOAD_DISTRICT: {
            return {
                ...state,
                listDistrict: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        default: {
            return { ...state }
        }
    }
}

export default BranchReducer
