export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
        roles: ['admin', 'sales'],
    },
    {
        label: 'PAGES',
        type: 'label',
    },
    {
        name: 'Transaction List',
        icon: 'receipt',
        path: '/transaction/default',
        roles: ['admin', 'user', 'sales'],
    },
    {
        name: 'Master Branch',
        icon: 'account_tree',
        path: '/branch/default',
        roles: ['admin'],
    },
    {
        name: 'Manage Branch',
        icon: 'account_tree',
        path: (user) => `/branch/edit/${user.branch_id}`,
        roles: ['user'],
    },
    {
        label: 'User',
        type: 'label',
        roles: ['admin'],
    },
    {
        name: 'User Admin & Sales',
        icon: 'group',
        path: '/user/default',
        roles: ['admin'],
    },
    // {
    //     name: 'User Admin',
    //     icon: 'launch',
    //     type: 'extLink',
    //     path: 'http://demos.ui-lib.com/matx-react-doc/',
    // },
]
